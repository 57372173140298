import { render, staticRenderFns } from "./reportDV.vue?vue&type=template&id=56d86e0a&scoped=true"
import script from "./reportDV.vue?vue&type=script&lang=js"
export * from "./reportDV.vue?vue&type=script&lang=js"
import style0 from "./reportDV.vue?vue&type=style&index=0&id=56d86e0a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d86e0a",
  null
  
)

export default component.exports