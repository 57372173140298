<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='2' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>财务指标</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<professionalFinancenNav :stage="stage" :type="type" :versions="versions" :currentFinance='2' />

							<div class="accountingPolicy">
								<div class="newCategory">会计政策统计表</div>
								<div>
									<p class="title">企业所得税税率及加计扣除政策统计表</p>
									<ul class="forms tax">
										<i></i>
										<li>
											<div>税种</div>
											<div>适用税率%</div>
										</li>
										<li>
											<div>所得税<span class="reRed">*</span></div>
											<div v-for="(item, i) in taxRevenue">
												<input type="number" v-model="taxRevenue[i]" @change="change"
													@blur="((ev)=>{percentage(ev,item,i)})" @focus="focus($event)"
													@wheel="method.disableWheel($event)">
												<span></span>
											</div>
										</li>
										<i></i>
									</ul>
									<ul class="forms tax" style="margin-top: 30px;">
										<i></i>
										<li>
											<div>项目</div>
											<div>加计扣除比例%</div>
										</li>
										<li>
											<div>研发费用<span class="reRed">*</span></div>
											<div>
												<input class="RDCost" type="number" ref="forbid" v-model="researchDeduction"
													@blur="((ev)=>{percentageRD(ev)})" @wheel="method.disableWheel($event)"
													:disabled="!deduction" />
												<span></span>
											</div>
										</li>
										<i></i>
									</ul>
								</div>
								<div>
									<p class="title">折旧摊销政策统计表（选填）</p>
									<p class="unit">单位：元</p>
									<ul class="forms accountant">
										<li>
											<div>项目</div>
											<div style="padding: 0 5px;">基准日当期（{{yearList[0].replace('年', '') - 1}}年全年）折旧/摊销额</div>
											<div>折旧摊销年限（年）</div>
											<div>残值率（%）</div>
										</li>
										<li v-for="(operation,index) in amortiza.amortiza">
											<div v-for="(item, i) in operation">
												<span v-if="i==0">{{operation[i]}}</span>
												<input v-else-if="i==2" type="number" ref="forbid" v-model="operation[i]"
													@blur="blur($event,'amortiza',i,index)" @wheel="method.disableWheel($event)"
													onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
												<input v-else type="number" v-model="operation[i]" @blur="blur($event,'amortiza',i,index)"
													@wheel="method.disableWheel($event)" ref="forbid" />

												<span class="showInput" v-if="i==0" style="display: none;">
													{{amortiza.amortiza[index][i]}}
												</span>
												<span class="showInput" v-else @click="inputFocus($event)">
													{{judgingCondition('amortiza',i,index)?method.formateNum(amortiza.amortiza[index][i],2):'-'}}
												</span>
											</div>
										</li>
										<li class="majorTotal">
											<div v-for="(item, i) in amortiza.payAmount">
												<span v-if="i==0">合计</span>
												<input type="number" value="-" v-if="i==2||i==3" disabled>
												<input type="number" v-else-if="i==1" v-model="amortiza.payAmount[i]"
													@blur="amortized($event,'amortiza',i)" @wheel="method.disableWheel($event)" />
												<span class="showInput" v-if="i==2||i==3">-</span>
												<span v-else-if="i==1" class="showInput" @click="inputFocus($event)">
													{{judgingCondition('payAmount',i,'','amortiza')?method.formateNum(amortiza.payAmount[i],2):'-'}}
												</span>
											</div>
										</li>
									</ul>
								</div>

								<div>
									<p class="title">未来资本性支出预测表（选填）</p>
									<div class="inputTabDesc">
										<p class="table-desc-tit">
											<img :src="require('@/assets/img/finance/hint.png')" alt="填表说明" />
											<span>提示</span>
										</p>
										<ul>
											<li style="padding: 0;">
												<span><i></i><i
														style="text-align: left;">仅对组合无形资产（不包括土地使用权、矿业权、水域使用权）的更新、改造支出进行预测，不考虑企业为扩大生产规模等其他因素的影响。</i></span>
											</li>
										</ul>
									</div>
									<p class="unit">单位：元</p>
									<ul class="forms depreciation">
										<li>
											<div style="width: 25%;">项目</div>
											<div class="expenditureYears" style="width: 75%;">
												<p>
													<span>预测期第一期</span>
													<span>预测期第二期</span>
													<span>预测期第三期</span>
												</p>
												<p>
													<span v-for="(item, index) in yearList">{{item.replace('年', 'E')}}</span>
												</p>
											</div>
										</li>
										<li v-for="(operation,index) in expenditure.expenditure">
											<div v-for="(item, i) in operation">
												<span v-if="i==0">{{operation[i]}}</span>
												<input type="number" data-type="number" v-if="i!=0" v-model="operation[i]"
													@blur="blur($event,'expenditure',i,index)" @wheel="method.disableWheel($event)" />
												<span class="showInput" v-if="i!=0" @click="inputFocus($event)">
													{{judgingCondition('expenditure',i,index)?method.formateNum(expenditure.expenditure[index][i],2):'-'}}
												</span>
											</div>
										</li>
									</ul>
								</div>

								<div>
									<p class="title">无形资产账面净值统计表（选填）</p>
									<p class="unit">单位：元</p>
									<ul class="forms bookAmountIntangibleAssets">
										<i></i>
										<li>
											<div>项目</div>
											<div>账面净值
												<i style="font-size: 14px;position: relative;top: -2px;">
													（{{createTime?getLastDayOfPreviousMonthInChinese(createTime):'xxxx年xx月xx日'}}）
												</i>
											</div>
										</li>
										<li>
											<div>土地使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.land"
													@blur="blurBookValue($event,'land')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.land!=''?this.method.formateNum(iaBookValue.land,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>矿业权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.mine"
													@blur="blurBookValue($event,'mine')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.mine?this.method.formateNum(iaBookValue.mine,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>水域使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.waters"
													@blur="blurBookValue($event,'waters')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.waters?this.method.formateNum(iaBookValue.waters,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>外购软件使用权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.outsourcedSoftware"
													@blur="blurBookValue($event,'outsourcedSoftware')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.outsourcedSoftware?this.method.formateNum(iaBookValue.outsourcedSoftware,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>专利权（如发明专利、实用新型专利、外观设计专利）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.patent"
													@blur="blurBookValue($event,'patent')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.patent?this.method.formateNum(iaBookValue.patent,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>专有技术</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.knowHow"
													@blur="blurBookValue($event,'knowHow')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.knowHow?this.method.formateNum(iaBookValue.knowHow,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>著作权（如计算机软件著作权、作品著作权等）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.copyright"
													@blur="blurBookValue($event,'copyright')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.copyright?this.method.formateNum(iaBookValue.copyright,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>商标权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.trademarkRight"
													@blur="blurBookValue($event,'trademarkRight')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.trademarkRight?this.method.formateNum(iaBookValue.trademarkRight,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>特许经营权</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.concession"
													@blur="blurBookValue($event,'concession')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.concession?this.method.formateNum(iaBookValue.concession,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>其他（如销售网络、客户关系、合同权益、域名等）</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.other"
													@blur="blurBookValue($event,'other')" @wheel="method.disableWheel($event)" />
												<span class="showInput" @click="inputFocus($event)">
													{{iaBookValue.other?this.method.formateNum(iaBookValue.other,2):'-'}}
												</span>
											</div>
										</li>
										<li>
											<div>总计</div>
											<div>
												<input type="number" data-type="number" v-model="iaBookValue.total" disabled />
												<span
													class="showInput">{{iaBookValue.total?this.method.formateNum(iaBookValue.total,2):''}}</span>
											</div>
										</li>
										<i></i>
									</ul>
								</div>


							</div>




							<div class="saveBox">
								<router-link v-if="this.$route.query.reportId" tag="span" class="last buttonBorder"
									:to="{ path: '/dataProfitSpecialty', query: { reportId:this.$route.query.reportId} }">上一步</router-link>
								<router-link v-else tag="span" class="last buttonBorder"
									:to="{ path: '/dataProfitSpecialty' }">上一步</router-link>
								<span data-isClick="false" @click="save">下一步</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import professionalFinancenNav from '../nav/professionalFinancenNav.vue'
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		components: {
			Header,
			fillinNav,
			professionalFinancenNav,

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '13',
				versions: '2',
				id: '',
				expenditure: {
					expenditure: [],
					payAmount: ['sum', '', '', '']
				},
				amortiza: {
					amortiza: [],
					payAmount: ['sum', '', '', '']
				},
				taxRevenue: [''],
				researchDeduction: '', //研发费用比例
				deduction: false, //研发费用 true 适用   false  不适用(研发费用为0)
				reportId: '',
				amortizationYear: 'xxxx年xx月xx日', //折旧摊销政策统计表年份
				cacheAlert: false,
				isCacheValue: '0',
				yearList: ['', '', ''],
				bookAmountIntangibleAssets: {
					dataResource: ''
				},
				iaBookValue: {
					id: '',
					valuationReportId: '',
					land: '', //土地使用权
					mine: '', //矿业权
					waters: '', //水域使用权
					outsourcedSoftware: '', //外购软件使用权
					dataResources: '', //数据资源
					patent: '', //专利权
					knowHow: '', //专有技术
					copyright: '', //著作权
					trademarkRight: '', //商标权
					concession: '', //特许经营权
					other: '', //其他
					total: '' //总计
				},
				hint: false,
				createTime: ''

			}
		},
		mounted() {

			this.getAccountingPolicy()
			this.getIABookValue()
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")
			},
			inputFocus(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'inline-block';
				e.currentTarget.previousSibling.focus();
			},
			change(i, j) {
				this.pageModify('true');
			},
			judgingCondition: function(key, i, index, data) {
				if (key == 'expenditure') {
					if (this.expenditure[key][index][i] === 0) {
						return true
					} else if (this.expenditure[key][index][i] != 0 && this.expenditure[key][index][i] != '-') {
						return true
					} else {
						return false
					}
				} else if (key == 'amortiza') {
					if (this.amortiza[key][index][i] === 0 || this.amortiza[key][index][i] === '0') {
						return true
					} else if (this.amortiza[key][index][i] != 0 && this.amortiza[key][index][i] != '-') {
						return true
					} else {
						return false
					}
				} else if (key == 'payAmount') {
					if (this[data][key][i] === 0) {
						return true
					} else if (this[data][key][i] != 0 && this[data][key][i] != '-') {
						return true
					} else {
						return false
					}
				}
			},
			percentage: function(e, val, i) {
				this.pageModify('true');
				this.isSave = false
				this.pageModify('true');
				if (val > 100) {
					this.showDialogBar(true, 'notEmpty-info', '请输入不大于100的数字')
					this.$set(this.taxRevenue, i, '')
					return false;
				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.taxRevenueContent) || (this
						.$route.query.reportId && this.taxRevenueContent && this.isCacheValue == '0')) {
					this.method.caching("taxRevenue", JSON.stringify(this.taxRevenue), this.type, this.stage, this)
				}


			},
			percentageRD: function(e) { //研发费用
				this.pageModify('true');
				this.isSave = false
				this.pageModify('true');
				if (this.researchDeduction > 100) {
					this.showDialogBar(true, 'notEmpty-info', '请输入不大于100的数字')
					this.researchDeduction = '';
					return false;
				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && !this.taxRevenueContent) || (this
						.$route.query.reportId && this.taxRevenueContent && this.isCacheValue == '0')) {
					this.method.caching("researchDeduction", this.researchDeduction, this.type, this.stage, this)
				}

			},
			amortized: function(e, name, i) {
				this.pageModify('true');
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'inline-block';
				var data = this.amortiza.payAmount[i];
				var totalNum = 0;
				for (var j = 0; j < this[name][name].length; j++) {
					totalNum += Number(this[name][name][j][i])
				}

				if (totalNum != 0) {
					if (data != totalNum) {
						this.$set(this.amortiza.payAmount, i, '')
					}
				} else {
					this.$set(this.amortiza.payAmount, i, Number(data).toFixed(2)) //保留两位
				}

			},
			blur: function(e, name, i, index) { //name：具体的科目名称  i：科目名称中自己所处的下标   index：products中第几个的下标
				this.pageModify('true');
				this.isSave = false

				e.currentTarget.nextSibling.style.display = 'inline-block';
				var data = this[name][name][index];

				var y = String(data[i]).indexOf("."); //获取小数点的位置
				var count = String(data[i]).length - y; //获取小数点后的个数
				var totalNum = 0;
				if (data[i]) {
					if (String(data[i]).indexOf(".") != -1) {
						this.$set(data, i, Math.round(Number(data[i]) * 100) / 100) //保留两位
					} else {
						if (data[i].length > 20) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(data, i, '')
							for (var j = 0; j < this[name][name].length; j++) {
								if (name == 'expenditure') {
									totalNum += Number(this.expenditure.expenditure[j][i])
									this.expenditure.payAmount[i] = Number(totalNum)
								}
							}

						} else {
							this.$set(data, i, Number(data[i]))
							for (var j = 0; j < this[name][name].length; j++) {
								totalNum += Number(this[name][name][j][i])
								if (name == 'expenditure') {
									this.expenditure.payAmount[i] = Number(totalNum)
								} else if (name == 'amortiza') {
									if (this.type == 2) {
										if (i != 2 && i != 3) {
											this.amortiza.payAmount[i] = Number(totalNum)
										}
									} else {
										if (i != 3 && i != 4) {
											this.amortiza.payAmount[i] = Number(totalNum)
										}
									}
								}
							}
						}
					}
				} else {
					this.$set(data, i, '')
					for (var j = 0; j < this[name][name].length; j++) {
						totalNum += Number(this[name][name][j][i])
						if (name == 'expenditure') {
							this.expenditure.payAmount[i] = Number(totalNum)
						} else if (name == 'amortiza') {
							if (this.type == 2) {
								if (i != 2 && i != 3) {
									this.amortiza.payAmount[i] = Number(totalNum)
								}
							} else {
								if (i != 3 && i != 4) {
									this.amortiza.payAmount[i] = Number(totalNum)
								}
							}
						}
					}
				}


				//调用缓存共用方法
				if (name == 'expenditure') {
					//调用缓存共用方法
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.expenditureContent) ||
						(this.$route.query.reportId && this.expenditureContent && this.isCacheValue == '0')) {
						this.method.caching("expenditure", JSON.stringify(this.expenditure), this.type, this.stage, this)
					}

				}

				//调用缓存共用方法
				if (name == 'amortiza') {
					//调用缓存共用方法
					if (!this.$route.query.reportId || (this.$route.query.reportId && !this.amortizaContent) || (
							this.$route.query.reportId && this.amortizaContent && this.isCacheValue == '0')) {
						this.method.caching("amortiza", JSON.stringify(this.amortiza), this.type, this.stage, this)
					}
				}


			},
			blurBookValue(e, key) {
				this.pageModify('true');
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'inline-block';

				this.iaBookValue.total = 0

				if (this.iaBookValue[key].length > 20) { //判断数据的合理性
					this.$Message.error({
						background: true,
						content: '请输入合理数据'
					});
					this.$set(this.iaBookValue, key, '')

					this.iaBookValue.total = Number(this.iaBookValue.land) + Number(this.iaBookValue.mine) + Number(this
							.iaBookValue.waters) + Number(this.iaBookValue.outsourcedSoftware) + Number(this.iaBookValue.patent) +
						Number(this.iaBookValue.knowHow) + Number(this.iaBookValue.copyright) + Number(this.iaBookValue
							.trademarkRight) + Number(this.iaBookValue.concession) + Number(this.iaBookValue.other)

				} else {
					this.iaBookValue.total = Number(this.iaBookValue.land) + Number(this.iaBookValue.mine) + Number(this
							.iaBookValue.waters) + Number(this.iaBookValue.outsourcedSoftware) + Number(this.iaBookValue.patent) +
						Number(this.iaBookValue.knowHow) + Number(this.iaBookValue.copyright) + Number(this.iaBookValue
							.trademarkRight) + Number(this.iaBookValue.concession) + Number(this.iaBookValue.other)
				}

			},
			getIABookValue() { //查询无形资产账面净值统计表
				this.$post(this.url + 'iABookValue/getIABookValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					}))
					.then(res => {
						if (res.data.content) {
							this.bookAmountIntangibleAssets = res.data.content
							this.createTime = res.data.content.createTime

							if (res.data.content.iaBookValue) {
								this.iaBookValue = res.data.content.iaBookValue
								this.iaBookValue.total = this.method.numRound(res.data.content.iaBookValue.total) - this.method
									.numRound(res.data.content.dataResource)
							}
						}
					})

			},
			getAccountingPolicy: function(close) {
				var this_ = this;
				this.$Spin.show(); //全局加载中

				var params = {
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					versions: this.versions,
					stage: this.stage,
					reportType: this.type,
				}

				this.$post(this.url + 'financeAccStatistics/queryFinanceAS', qs.stringify(params))
					.then((res) => {
						this.$Spin.hide();
						var expenditureProduct = ["房屋建筑物", "房屋构筑物", "车辆", "机器设备", "办公家具", "电子设备", "住宅用地",
							"商业用地", "工业用地", "其他无形资产", "长期待摊费用"
						];

						//折旧摊销政策统计表年限
						this.yearList = res.data.content.years;
						this.amortizationYear = (res.data.content.years[0].replace('年', '') - 1) +
							'年12月31日';
						this.id = res.data.content.id;

						this.expenditureContent = res.data.content.expenditure
						this.amortizaContent = res.data.content.amortiza
						this.taxRevenueContent = res.data.content.taxRevenue
						this.isCacheValue = res.data.content.isCache;
						// 研发费用
						if (res.data.content.deduction) {
							this.deduction = true
							this.researchDeduction = res.data.content.researchDeduction ? Math.round(res.data.content
								.researchDeduction * 100) : ''
						} else {
							this.researchDeduction = res.data.content.researchDeduction
						}

						if (this.isCacheValue == '0' && close == '0') {
							this.method.clearCaching(this.type, this.stage, this)
						}

						if (res.data.content.expenditure) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
								this.defaultData();
							} else { //正式数据
								this.expenditure = JSON.parse(res.data.content.expenditure);
							}

						} else {
							this.expenditure.expenditure = [];
							this.expenditure.expenditure.push([])
							for (var j = 0; j < 4; j++) {
								if (j == 0) {
									this.expenditure.expenditure[0].push("组合无形资产更新、改造支出")
								} else {
									this.expenditure.expenditure[0].push("")
								}
							}


						}


						if (res.data.content.amortiza) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
								this.defaultData();
							} else { //正式数据
								this.amortiza = JSON.parse(res.data.content.amortiza)
							}
						} else {
							this.amortiza.amortiza = [];
							for (var i = 0; i < 11; i++) {
								this.amortiza.amortiza.push([])
								for (var j = 0; j < 4; j++) {
									if (j == 0) {
										this.amortiza.amortiza[i].push(expenditureProduct[i])
									} else {
										this.amortiza.amortiza[i].push("")
									}
								}
							}
						}

						if (res.data.content.taxRevenue) {
							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.cacheAlert = true;
								this.defaultData();
							} else { //正式数据
								this.taxRevenue = JSON.parse(res.data.content.taxRevenue);
							}
						}


					})

			},
			save() {

				if (!this.taxRevenue[0]) {
					this.showDialogBar(true, 'notEmpty-info', '企业所得税不能为空');
					return false;
				} else if (this.taxRevenue[0] > 25) {
					this.showDialogBar(true, 'notEmpty-info', '请正确填写所得税税率，上限为25%');
					return false;
				}

				if (this.researchDeduction === '') {
					this.showDialogBar(true, 'notEmpty-info', '研发费用不能为空');
					return false;
				}


				if (this.method.numRound(this.bookAmountIntangibleAssets.netWorth) && (this.method.numRound(this.iaBookValue
						.total) + this.method.numRound(this.bookAmountIntangibleAssets.dataResource)) > this.method.numRound(this
						.bookAmountIntangibleAssets.netWorth)) {
					this.hint = true
					this.showDialogBar(true, 'notEmpty-info', '请核实无形资产账面净值总计应与数据资产中的无形资产账面净值一致');
					return false;
				}


				this.saveInterface()

			},
			saveInterface() {
				this.$Spin.show(); //全局加载中

				var params = {
					id: this.id,
					reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
					stage: this.stage,
					reportType: this.type,
					versions: this.versions,
					expenditure: JSON.stringify(this.expenditure),
					amortiza: JSON.stringify(this.amortiza),
					taxRevenue: JSON.stringify(this.taxRevenue),
					researchDeduction: this.researchDeduction
				}

				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'financeAccStatistics/insertFinanceAS', qs.stringify(params))
						.then((res) => {
							if (res.data.code == 200) {
								this.saveIABookValue()
							}
						})

				}
			},
			saveIABookValue() {
				var copy = JSON.stringify(this.iaBookValue)
				var iaBookValue = JSON.parse(copy)
				iaBookValue.dataResources = this.bookAmountIntangibleAssets.dataResource
				iaBookValue.total = Number(this.iaBookValue.total) + Number(this.bookAmountIntangibleAssets.dataResource)

				console.log(iaBookValue)

				this.$post(this.url + 'iABookValue/saveIABookValue', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						iaBookValue: JSON.stringify(iaBookValue),
						reportType: this.type
					}))
					.then(res => {
						this.$Spin.hide();
						this.$router.push({
							name: 'dataAssetSpecialty',
							query: {
								reportId: res.data.content.valuationReportId
							}
						})

					})
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getAccountingPolicy();
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.type, this.stage, this, 'getAccountingPolicy')
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
				if (this.hint) {
					this.saveInterface()
				}
			}



		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.title {
		font-weight: bold;
		font-size: 16px;
		color: #FFFFFF;
		margin: 35px auto 20px;
		text-align: center;
	}

	.tax {
		li {
			>div:first-child {
				width: 46%;
			}

			>div:last-child {
				width: 54%;
			}
		}
	}

	.accountant,
	.depreciation {
		li {
			>div {
				width: 25%;
			}

			>div:first-child {
				span {
					width: 70%;
					text-align: left;
				}
			}

		}
	}

	.depreciation {

		li:nth-of-type(1) {
			>div:first-child::after {
				height: 80px;
			}
		}

		li {
			>div:first-child {
				span {
					width: auto;
				}
			}
		}

		.expenditureYears {
			p:first-child {
				border-bottom: 1px solid #29FFFC;
				padding-bottom: 10px;

				span {
					font-weight: bold;
				}
			}

			p:last-child {
				padding-top: 10px;
			}

			P {
				span {
					width: 33%;
					position: relative;
				}

				>span:not(:last-child)::after {
					content: '';
					display: block;
					width: 2px;
					height: 25px;
					background: #29FFFC;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.bookAmountIntangibleAssets {
		li {
			>div:first-child {
				width: 42%;
			}

			>div:last-child {
				width: 58%;
			}
		}
	}


	ul {

		li:not(:nth-of-type(1)) {
			border-top: 1px solid #29FFFC;
		}

		li {
			margin: 0 auto;
			padding: 16px 0;
			text-align: center;
			position: relative;
			display: flex;
			align-items: center;



			input {
				width: 80%;
				max-width: 200px;
				height: 30px;
				padding-right: 6px;
				font-size: 14px;
				background: #1237c6;
				color: #fff;
				text-align: right;
				border: 1px solid #29FFFC;
				margin: 0 auto;
			}

			input:disabled {
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			input:disabled+.showInput {
				display: block;
				background: #4B9BFF;
				border-color: #4B9BFF;
			}

			>div:not(:last-child)::after {
				content: '';
				display: block;
				width: 2px;
				height: 25px;
				background: #29FFFC;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			>div:first-child {
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				padding: 0 10px;
			}

			>div:last-child {
				border-right: none;
			}

			>div {
				color: #fff;
				position: relative;

				.showInput {
					width: 78%;
					max-width: 198px;
					height: 27px;
					background: #1237c6;
					line-height: 28px;
					font-size: 14px;
					padding: 0 6px;
					color: #fff;
					text-align: right;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					overflow: hidden;
				}
			}
		}

		li:nth-of-type(1) {
			padding: 20px 0;


			div {
				font-size: 16px;
				font-weight: bold;
			}

		}


		li:not(:nth-of-type(1))::before {
			content: '';
			display: block;
			width: 10px;
			height: 9px;
			background: url(../../../assets/img/contribution/cornerMark.png) center center no-repeat;
			background-size: contain;
			position: absolute;
			top: -1px;
			left: 0;
		}

	}
</style>