<template>
	<div class="intelligentDV main" id="app">
		<Header class="homeHeader" />
		<div class="dataAsset" id="dataAsset">
			<div>
				<div>
					<div class="rotation3D-baseMap"></div>
					<div id="rotation3D" class="rotation3D">
						<button class="center">中心</button>
						<div class="itemList" id="itemList">
							<div class="rotation3D__item" :class="item.type" v-for="item in itemList">
								<div class="scale">
									<div class="cont">
										<p>{{item.name}}</p>
									</div>
									<div class="baseImg"
										:style="{'background':'url('+require('../assets/img/home/'+item.icon)+') center center no-repeat'}">
									</div>
								</div>
							</div>
						</div>
						<img class="pedestalIcon" src="../assets/img/home/pedestalIcon.png" alt="" />
						<div class="lineList">
							<div class="lineList">
								<div class="rotation3D__line blue" v-for="item in itemList">
									<div class="pos">
										<svg width="10" height="400">
											<path id="path1" d="M0 400, 0 0" stroke-dasharray="10,10" />
										</svg>
										<div class="dot dot1 el-icon-caret-right"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<img style="position: absolute;top: 31.5%;left: 50%;margin-left: -495.5px;transform: scale(0.9);"
					src="../assets/img/home/streamer.png" />

				<div class="groundFloor">
					<div class="appraisement">
						<router-link tag="a" :to="{path: '/dataInfoSpecialty', query: {type: '13'}}">
							<img src="../assets/img/home/valuationIcon.png" />
							<p>立即估值</p>
						</router-link>
					</div>
					<ul>
						<li style="left: 0;">
							<img src="../assets/img/home/mlEstimationMethod.png" alt="ML估值法" />
						</li>
						<li style="left:250px;top:-10px;">
							<img src="../assets/img/home/NPCM.png" alt="收入贡献法" />
						</li>
						<li style="left: 454px;top:-18px;">
							<img src="../assets/img/home/dataFeature.png" alt="数据特征" />
						</li>
						<li style="left: 936px;top:-9px;">
							<img src="../assets/img/home/dataRisk.png" alt="数据风险" />
						</li>
						<li style="left: 1149px;top:-9px;">
							<img src="../assets/img/home/dataQuality.png" alt="数据质量" />
						</li>
						<li style="left: 1364px;top:-1px;">
							<img src="../assets/img/home/dataFlow.png" alt="数据流通" />
						</li>
					</ul>
				</div>
			</div>

		</div>


		<Footer />
	</div>
</template>

<script>
	import Header from '../common/header.vue'
	import Footer from '../common/footer.vue'
	import '../assets/css/common.css'
	import '../assets/css/rotation3D.css'
	import '../assets/js/rotation3D.js'


	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				width: 0,
				itemList: [{
					name: '数据规模',
					icon: 'scaleData.png',
				}, {
					name: '数据规范性',
					icon: 'dataNormalization.png',
				}, {
					name: '数据完整性',
					icon: 'dataIntegrity.png',
				}, {
					name: '数据准确性',
					icon: 'dataAccuracy.png',
				}, {
					name: '数据安全性',
					icon: 'dataSecurity.png',
				}, {
					name: '数据合法性',
					icon: 'dataLegalization.png',
				}],
			}
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}

			if (this.$route.query.encipher) {
				localStorage.userName = this.method.base64ToUtf8(this.$route.query.encipher)
			}

		},
		mounted() {
			sessionStorage.versions = 2
			sessionStorage.stage = ''
			sessionStorage.reportType = 13

			sessionStorage.removeItem('isHistoryCache')
			sessionStorage.removeItem('netWorth')

			this.width = window.innerWidth
			window.addEventListener('resize', () => {
				this.width = window.innerWidth
				this.setRotation3D()
				this.transform()
			})

			this.setRotation3D()
			this.transform()

		},
		methods: {
			setRotation3D() {
				new Rotation3D({
					id: '#rotation3D',
					farScale: 0.5,
					// farScale: 1,
					xRadius: 20, //x半径压缩
					yRadius: 230, //y半径压缩
					// yRadius: 0, //y半径压缩
					autoPlay: true,
					autoPlayDelay: 1500,
				})
			},
			transform() {
				// 获取元素
				var element = document.getElementById('dataAsset');

				// 计算缩放比例，假设你想要元素在一屏内完全显示
				// var scaleX = window.innerWidth /2000;
				var scaleX = window.innerWidth / 1804;
				var scaleY = (window.innerHeight - 130) / 957;

				var calculateScaleY = scaleY
				// 应用缩放 
				// element.style.transform = 'translate(-50%, -38%) scale(' + scaleX + ',' + scaleY + ')';

				var aspectRatio = 1604 / 957; // 假设元素的原始宽高比例为 1804:957

				// 计算缩放比例
				// if (window.innerWidth < 1330) {
				// 	var scale = Math.min(1330 / 1604, (window.innerHeight - 85) / 957);
				// } else {
				// 	var scale = Math.min(window.innerWidth / 1604, (window.innerHeight - 85) / 957);
				// }

				if (window.innerWidth < 1200) {
					var scale = Math.min(1200 / 1604, (window.innerHeight - 85) / 957);
				} else {
					var scale = Math.min(window.innerWidth / 1604, (window.innerHeight - 85) / 957);
				}

				// var height = window.innerHeight < 650 && window.innerWidth < 1400 ? element.offsetHeight / 2 + 85 : element
				// 	.offsetHeight / 2 + 77


				// 应用缩放和偏移 calc(-50% - 40px)  translate(-50%, -50%)
				element.style.transform = 'translate(-50%,-50%) scale(' + scale + ')';

				// element.style.marginTop = -height + 'px'



			}




		}


	}
</script>

<style scoped lang="less">
	.intelligentDV {
		background: url(../assets/img/backgroundDV.jpg);
		background-size: cover;
	}

	.rotation3D-baseMap {
		position: relative;
		left: 50%;
		// top: 268px;
		top: 131px;
		margin-left: -527.5px;
		width: 1055px;
		height: 248px;
		background: url("../assets/img/home/pedestal.png") no-repeat;
		background-size: contain;
		/* background-position: 0px -32px; */
	}

	.pedestalIcon {
		position: absolute;
		top: 21%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 13;
	}

	// .rotation3D-baseMap::before {
	// 	position: absolute;
	// 	left: -12px;
	// 	right: 0;
	// 	top: -137px;
	// 	margin: auto;
	// 	z-index: 99;
	// 	width: 418px;
	// 	height: 256px;
	// 	display: block;
	// 	content: '';
	// 	background: url("../assets/img/home/pedestalIcon.png");
	// }

	#dataAsset {
		position: relative;
		top: 50%;
		left: 50%;
		margin-top: 4%;
	}

	.groundFloor {
		width: 1860px;
		height: 471px;
		background: url("../assets/img/home/groundFloor.png") center center no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -16px;
		left: 50%;
		margin-left: -930px;
		text-align: center;

		.appraisement {
			width: 250px;
			height: 250px;
			background: url("../assets/img/home/appraisement.png") center center no-repeat;
			background-size: contain;
			position: relative;
			margin: 0 auto;
			top: -8%;
			z-index: 222;
			cursor: pointer;
			transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡

			a {
				display: block;
			}

			img {
				margin-top: 75px;
			}

			p {
				width: 100%;
				text-align: center;
				font-size: 32px;
				font-weight: bold;
				color: #FFFFFF;
				text-shadow: 0px 5px 9px rgba(0, 0, 0, 0.87);
				-webkit-text-stroke: 1px #39BEF6;
				text-stroke: 1px #39BEF6;
				position: absolute;
				bottom: 71px;
			}
		}

		.appraisement:hover {
			background: url("../assets/img/home/appraisementHover.png") center center no-repeat;
			background-size: contain;
			transform: scale(1.1) translateY(10px); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		}

		ul {
			display: flex;
			position: relative;
			top: -204px;
			left: 118px;

			li {
				position: absolute;
			}
		}

	}
</style>