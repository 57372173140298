import axios from 'axios';
import router from '../router';
import domain from '../assets/js/domain.js'
// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = domain.testUrl;
// axios.defaults.baseURL = 'http://192.168.12.213:8089/'; //请求接口的域名 测试

console.log(domain,sessionStorage.channel)
console.log('config-------',window.channel)

//http request 拦截器
axios.interceptors.request.use(
	config => {
		let token = localStorage.getItem('userToken');
		//每个http header都加上token
		config.headers = {
			'Authorization': token,
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		}

		return config
	},
	err => {
		return Promise.reject(err)
	}
)

axios.interceptors.response.use(
	response => {
		return response
	},
	err => {
		console.log(err.response)
		if (err.response.status == 401) { //登录失效
			localStorage.removeItem("userToken");
			localStorage.removeItem("userName");
			router.push({
				path: "/login",
				querry: {
					redirect: router.currentRoute.fullPath
				} //从哪个页面跳转
			})
		} else if (err.response.status == 404) {
			router.push({
				path: "/notFound"
			})
		} else {
			router.push({
				path: "/serverError"
			})
		}
	}
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}, headers = {}) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params,
				headers: headers
			})
			.then(response => {
				resolve(response.data);
			})
	})
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(response => {
				resolve(response);
			}, err => {
				reject(err)
			})
	})
}
